import React from "react";
import "./skills.css";
import emoji1 from "../../img/glasses.png";
import emoji2 from "../../img/heartemoji.png";
import emoji3 from "../../img/humble.png";
import lan1 from "../../img/c++.png";
import lan2 from "../../img/html.png";
import lan3 from "../../img/reactimg.png";
import lan4 from "../../img/python.png";
import lan5 from "../../img/blender.png";
import lan6 from "../../img/sql.png";
import lan7 from "../../img/csslogo.png";
import Card from "../Card/card";
const skills = () => {
  return (
    <div className="Skills" id='skills'>
      <div className="headofskill">
        <span>Skills</span>
        <span>Learn, Apply, Succeed</span>
        <span>
          My greatest skill lies in my ability to learn and
          <br />
          adapt to any situation for effective problem-solving.
        </span>
        <div
          className="blur1 s-blur1"
          style={{ background: "#ABF1FF94" }}
        ></div>
      </div>
      <div className="Cards">
        <div style={{ top: "1rem", left: "33rem" }}>
          <Card
            emoji={emoji2}
            heading={"Languages"}
            detail={
              "C++, SQL, Python, HTML, CSS, JavaScript, Bash, JAVA(Basic)"
            }
          />
        </div>
        <div style={{ top: "13rem", left: "13rem" }}>
          <Card
            emoji={emoji1}
            heading={"Tools"}
            detail={
              "MySQL, PostgreSQL, SQLite, Postman, Blender, Unreal Engine, Unity, Git and many more."
            }
          />
        </div>
        <div style={{ top: "25rem", left: "33rem" }}>
          <Card
            emoji={emoji3}
            heading={"Soft Skills"}
            detail={
              "Leadership, Adaptability, Event Management, Public Speaking, Time Management, Learning"
            }
          />
        </div>
        <div
          className="blur1 s-blur2"
          style={{ background: "var(--purple)" }}
        ></div>
        {/*Stickers for better design*/}
        <div className="float-element logos">
        <img src={lan1} alt="" />
        <img src={lan2} alt="" />
        <img src={lan3} alt="" />
        <img src={lan4} alt="" />
        <img src={lan5} alt="" />
        <img src={lan6} alt="" />
        <img src={lan7} alt="" />
        </div>
      </div>
    </div>
  );
};

export default skills;
