import React from "react";
import "./Intro.css";
import Github from "../../img/github.png";
import LinkedIn from "../../img/linkedin.png";
import instagram from "../../img/instagram.png";
import v1 from "../../img/v4.png";
import rahul1 from "../../img/rahul1.png";
import rahul2 from "../../img/rahul3.png";
import FloatingDiv from "../FloatingDiv/FloatingDiv";
import Crown from "../../img/crown.png";
import thumb from "../../img/thumbup.png";
import Hanumanji from "../../img/hanumanjii.png";
import Resume from "../../Components/Resume/Sample.pdf";
import "../Skills/skills.css";
const intro = () => {
  return (
    <div className="intro-section">
      <div className="intro-left">
        <div className="intro-name">
          <span>Hi!👋 I am </span>
          <span>Rahul Malik</span>
          <span>
            I'm a Computer Science engineer with a strong problem-solving
            background. My passion lies in designing and delivering top-quality
            work. I thrive on creative challenges and aim to push the boundaries
            of technology, striving for innovative solutions that make a
            meaningful impact.
          </span>
        </div>
        <a href={Resume} download>
        <button className="button intro-button">Download Resume/CV</button>
        </a>
        <div className="intro-icons">
          <a
            href="https://github.com/Rahulmalik126"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Github} alt="" />
          </a>
          <a
            href="https://www.linkedin.com/in/rahul-malik-smile/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={LinkedIn} alt="" />
          </a>
          <a
            href="https://www.instagram.com/1rmalik1/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagram} alt="" />
          </a>
        </div>
      </div>
      <div className="intro-right">
        <img src={v1} alt="" />
        <img src={rahul1} alt="" />
        <img src={rahul2} alt="" />
        <img src={Hanumanji} alt=""/>
        <div className="floating-div divstylescreen1" >
          <FloatingDiv image={Crown} txt1="Web" txt2="Development" />
        </div>
        <div className="floating-div divstylescreen2" >
          <FloatingDiv image={thumb} txt1="Software" txt2="Development" />
        </div>
        <div className="blur1"></div>
        <div className="blur2"></div>
        <div className="blur3"></div>
      </div>
    </div>
  );
};

export default intro;
