import React from 'react'
import'./exp.css'
import intern from "../../img/internship.png";
import cn from "../../img/cnlogo.png";
import jiit from "../../img/Logo-jiit.png";
const exp = () => {
  return (
    <div className='experience' id='exp'>
        <div className="headofskill">
        <span>Experience</span>
        <span>Knowledge Through Work</span>
        <span>
        <h3>• JAYPEE INSTITUTE OF INFORMATION TECHNOLOGY</h3>
Summer Training internship (June 2022 - July 2022)<br/>
◦ Developing Web Application: Designing the frond-end<br/> and back-end of the application with React-Native and<br/>
Node.js, redux for street vendors.<br/>
<br/>
<br/>
<h3>• CODING NINJAS</h3>
Teaching Assistant internship (August 2022 - December 2022)<br/>
◦ Taking doubt sessions: Solving Doubts of students on the <br/>platform related to different DSA questions.<br/>
◦ Debugging codes: Identifying and removing errors from the <br/>solutions(code) of students<br/>
◦ Impact: Helped 300+ students by solving their problems<br/> with 4.89 average rating.<br/>
        </span>
        <div
          className="blur1 s-blur1"
          style={{ background: "#ABF1FF94" }}
        ></div>
    </div>
    <div className='right'>
        <div className='maincircle'>
            <div className='seccircle'>
                <img src={cn} alt="" width={105} height={75} />
            </div>
            <div className='seccircle'>
                <img src={intern}alt="" width={80} height={75}/>
            </div>
            <div className='seccircle'>
                <img src={jiit} alt="" width={85} height={90}/>
            </div> 
        </div>
        <div className=' backcircle bcircle'></div>
        <div className=' backcircle ycircle'></div>
    </div>
    </div>
  )
}

export default exp