import React from "react";
import fish1 from "../../img/fish1.png";
import fish2 from "../../img/fish2.png";
import fish3 from "../../img/fish3.png";
import wave from "../../img/wavefoot.png";
import { FaInstagram, FaLinkedin, FaGithub } from "react-icons/fa";
import "./footer.css";
const footer = () => {
  return (
    <div className="footer">
      <img src={wave} alt="" style={{ width: "100%" }} />
      <div className=" float-element f-content">
        <span className="e-mail">0rahulmalik0@gmail.com</span>
        <div className="f-icons">
          <a
            href="https://www.instagram.com/1rmalik1/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram color="white" size="3rem" />
          </a>
          <a
            href="https://www.linkedin.com/in/rahul-malik-smile/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin color="white" size="3rem" />
          </a>
          <a
            href="https://github.com/Rahulmalik126"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGithub color="white" size="3rem" />
          </a>
        </div>
        <div className="fishes">
          <img className="fish1" src={fish1} alt="" height={2000}/>
          <img className="fish2" src={fish2} alt="" />
          <img className="fish3" src={fish3} alt="" />
        </div>
      </div>
    </div>
  );
};

export default footer;
