import Navbar from "./Components/Navbar/Navbar";
import "./App.css";
import Intro from "./Components/Intro/Intro";
import Skills from "./Components/Skills/skills";
import Experience from "./Components/Experience/exp";
import Portfolio from "./Components/Portfolio/portfolio";
import Contact from "./Components/Contact/contact";
import Footer from "./Components/Footer/footer";
import { Link } from "react-scroll";
function App() {
  return (
    <div className="App">
      <Navbar />
      <Intro />
      <Link spy={true} to="Nav" smooth={true}>
        <button id="scrollButton">➤</button>
      </Link>
      <Skills />
      <Experience />
      <Portfolio />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
