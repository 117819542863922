import React from 'react';
import './portfolio.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import anim2 from '../../img/anim2.png';
import anim1 from '../../img/anim1.png';
import app from '../../img/app.png';
import game from '../../img/game.png';
import buddy from '../../img/buddy.png';


const Portfolio = () => {
  return (
    <div className='portfolio' id='portfolio'>
      <span>Portfolio</span>
      <br />
      <span>Recent Projects</span>
      <Swiper
      spaceBetween={30}
      slidesPerView={3}
      grabCursor={true}
      className='portfolio-slider'
      >
        <SwiperSlide>
          <img src={anim2} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={anim1} alt="" />
        </SwiperSlide>{" "}
        <SwiperSlide>
          <img src={app} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={game} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={buddy} alt="" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Portfolio;