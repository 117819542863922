import React from 'react'
import './Nbar.css'
import {Link} from 'react-scroll'
const Navbar = () => {
  return (
    <div className='nav-wrapper' id='Nav'>
      <div className="nav-left">
        <div className="nav-name">
        &lt; Rahul / &gt;
           </div>
      </div>
      <div className="nav-right">
        <div className="nav-list">
            <ul style={{listStyleType:'none'}}>
              <Link spy={true} to='Navbar' smooth={true} >
                <li>Home</li>
              </Link>
              <Link spy={true} to='skills' smooth={true} >
                <li>Skills</li>
              </Link>
              <Link spy={true} to='exp' smooth={true} >
                <li>Experience</li>
              </Link>
              <Link spy={true} to='portfolio' smooth={true} >
                <li>Portfolio</li>
              </Link>
            </ul>
        </div>
        <Link spy={true} to='con' smooth={true} >
        <button className='button nav-button'>Contact Me</button>
        </Link>
      </div>
    </div>
  )
}

export default Navbar
